var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _vm.editable
        ? _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
            },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailInfo",
                  attrs: { title: "설비 기본정보" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable &&
                          _vm.popupParam.noPlan === "Y" &&
                          !_vm.updateMode &&
                          !_vm.disabled
                            ? _c("c-btn", {
                                attrs: { label: "설비선택", icon: "add" },
                                on: { btnClicked: _vm.add },
                              })
                            : _vm._e(),
                          _vm.editable && _vm.updateMode && !_vm.disabled
                            ? _c("c-btn", {
                                attrs: {
                                  showLoading: false,
                                  label: "정비완료",
                                  icon: "save",
                                  color: "blue",
                                },
                                on: { btnClicked: _vm.resultComplete },
                              })
                            : _vm._e(),
                          _vm.editable && !_vm.disabled
                            ? _c("c-btn", {
                                attrs: {
                                  url: _vm.saveUrl,
                                  isSubmit: _vm.isSave,
                                  param: _vm.result,
                                  mappingType: _vm.saveType,
                                  label: "정비결과 저장",
                                  icon: "save",
                                },
                                on: {
                                  beforeAction: _vm.saveInspection,
                                  btnCallback: _vm.saveCallback,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _vm.editable && !_vm.result.equipmentCd
                      ? _c(
                          "div",
                          { staticClass: "col-12" },
                          [
                            _c("c-label-text", {
                              attrs: { title: "설비를 선택하세요." },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.editable && _vm.result.plantNm
                      ? _c(
                          "div",
                          { staticClass: "col-3" },
                          [
                            _c("c-label-text", {
                              attrs: {
                                title: "사업장",
                                value: _vm.result.plantNm,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.editable && _vm.result.equipmentTypeCd
                      ? _c(
                          "div",
                          { staticClass: "col-3" },
                          [
                            _c("c-label-text", {
                              attrs: {
                                title: "설비유형",
                                value: _vm.result.equipmentTypeNm,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.editable && _vm.result.equipmentCd
                      ? _c(
                          "div",
                          { staticClass: "col-3" },
                          [
                            _c("c-label-text", {
                              attrs: {
                                title: "설비코드",
                                value: _vm.result.equipmentCd,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.editable && _vm.result.equipmentName
                      ? _c(
                          "div",
                          { staticClass: "col-3" },
                          [
                            _c("c-label-text", {
                              attrs: {
                                title: "설비명",
                                value: _vm.result.equipmentName,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.editable && _vm.result.recentMaintainDate
                      ? _c(
                          "div",
                          { staticClass: "col-3" },
                          [
                            _c("c-label-text", {
                              attrs: {
                                title: "이전정비일",
                                value: _vm.result.recentMaintainDate,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.editable && _vm.result.relatedLawsName
                      ? _c(
                          "div",
                          { staticClass: "col-6" },
                          [
                            _c("c-label-text", {
                              attrs: {
                                title: "관련법규",
                                value: _vm.result.relatedLawsName,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("div", { staticClass: "col-12" }),
                  ]),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "q-form",
            { ref: "editForm" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "설비 정비결과", bgClass: "" },
                },
                [
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c(
                          "c-field",
                          {
                            attrs: {
                              required: "",
                              disabled: _vm.disabled,
                              label: "정비부서 입회자",
                              name: "memDeptEntrantUserId",
                              type: "user",
                            },
                            model: {
                              value: _vm.result.memDeptEntrantUserId,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.result,
                                  "memDeptEntrantUserId",
                                  $$v
                                )
                              },
                              expression: "result.memDeptEntrantUserId",
                            },
                          },
                          [_vm._v(" >")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-select", {
                          attrs: {
                            required: "",
                            disabled: _vm.disabled,
                            label: "정비유형",
                            codeGroupCd: "MDM_MAINTENANCE_KIND_CD",
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                          },
                          model: {
                            value: _vm.result.equipmentCheckTypeCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.result, "equipmentCheckTypeCd", $$v)
                            },
                            expression: "result.equipmentCheckTypeCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-field", {
                          attrs: {
                            required: "",
                            disabled: _vm.disabled,
                            label: "정비예정자",
                            name: "maintenanceDueUserId",
                            type: "user",
                          },
                          model: {
                            value: _vm.result.maintenanceDueUserId,
                            callback: function ($$v) {
                              _vm.$set(_vm.result, "maintenanceDueUserId", $$v)
                            },
                            expression: "result.maintenanceDueUserId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            required: "",
                            disabled: _vm.disabled,
                            editable: _vm.editable,
                            label: "정비예정일",
                            name: "maintenanceDueDate",
                          },
                          model: {
                            value: _vm.result.maintenanceDueDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.result, "maintenanceDueDate", $$v)
                            },
                            expression: "result.maintenanceDueDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-field", {
                          attrs: {
                            disabled: _vm.disabled,
                            label: "실제정비자",
                            name: "maintenanceUserId",
                            type: "user",
                          },
                          model: {
                            value: _vm.result.maintenanceUserId,
                            callback: function ($$v) {
                              _vm.$set(_vm.result, "maintenanceUserId", $$v)
                            },
                            expression: "result.maintenanceUserId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            required: "",
                            disabled: _vm.disabled,
                            editable: _vm.editable,
                            label: "실제정비일",
                            name: "maintenanceDate",
                          },
                          model: {
                            value: _vm.result.maintenanceDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.result, "maintenanceDate", $$v)
                            },
                            expression: "result.maintenanceDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-select", {
                          attrs: {
                            required: "",
                            disabled: _vm.disabled,
                            editable: _vm.editable,
                            codeGroupCd: "MIM_CHECK_RESULT_CD2",
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "inspResultAfterMemCd",
                            label: "정비후 정비결과",
                          },
                          model: {
                            value: _vm.result.inspResultAfterMemCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.result, "inspResultAfterMemCd", $$v)
                            },
                            expression: "result.inspResultAfterMemCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            disabled: _vm.disabled,
                            editable: _vm.editable,
                            label: "정비시간",
                            range: true,
                            minuteStep: 10,
                            type: "time",
                            name: "maintenanceTimeList",
                          },
                          model: {
                            value: _vm.maintenanceTimeList,
                            callback: function ($$v) {
                              _vm.maintenanceTimeList = $$v
                            },
                            expression: "maintenanceTimeList",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-datepicker", {
                          attrs: {
                            disabled: _vm.disabled,
                            editable: _vm.editable,
                            label: "운전정지시간",
                            range: true,
                            minuteStep: 10,
                            type: "time",
                            name: "stopDrivingTimeList",
                          },
                          model: {
                            value: _vm.stopDrivingTimeList,
                            callback: function ($$v) {
                              _vm.stopDrivingTimeList = $$v
                            },
                            expression: "stopDrivingTimeList",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.editable
                      ? _c(
                          "div",
                          { staticClass: "col-3" },
                          [
                            _c("c-text", {
                              attrs: {
                                afterIcon: !_vm.disabled
                                  ? [
                                      {
                                        name: "search",
                                        click: true,
                                        callbackName: "searchMocMaster",
                                        color: "teal",
                                      },
                                      {
                                        name: "close",
                                        click: true,
                                        callbackName: "removeMocMaster",
                                        color: "red",
                                      },
                                    ]
                                  : null,
                                editable: _vm.editable,
                                label: "MOC번호",
                                disabled: _vm.disabled,
                                search: true,
                                name: "mocId",
                              },
                              on: {
                                searchMocMaster: _vm.searchMocMaster,
                                removeMocMaster: _vm.removeMocMaster,
                              },
                              model: {
                                value: _vm.result.mocId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.result, "mocId", $$v)
                                },
                                expression: "result.mocId",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "정비자 자격사항",
                            name: "maintenanceQualifications",
                          },
                          model: {
                            value: _vm.result.maintenanceQualifications,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.result,
                                "maintenanceQualifications",
                                $$v
                              )
                            },
                            expression: "result.maintenanceQualifications",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "정비원인",
                            name: "maintenanceCause",
                          },
                          model: {
                            value: _vm.result.maintenanceCause,
                            callback: function ($$v) {
                              _vm.$set(_vm.result, "maintenanceCause", $$v)
                            },
                            expression: "result.maintenanceCause",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "정비내용",
                            name: "maintenanceContent",
                          },
                          model: {
                            value: _vm.result.maintenanceContent,
                            callback: function ($$v) {
                              _vm.$set(_vm.result, "maintenanceContent", $$v)
                            },
                            expression: "result.maintenanceContent",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "정비결과요약",
                            name: "maintenanceResultSummary",
                          },
                          model: {
                            value: _vm.result.maintenanceResultSummary,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.result,
                                "maintenanceResultSummary",
                                $$v
                              )
                            },
                            expression: "result.maintenanceResultSummary",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            disabled: _vm.disabled,
                            label: "관리자의 검토의견",
                            name: "reviewOpinionOfMananger",
                          },
                          model: {
                            value: _vm.result.reviewOpinionOfMananger,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.result,
                                "reviewOpinionOfMananger",
                                $$v
                              )
                            },
                            expression: "result.reviewOpinionOfMananger",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }